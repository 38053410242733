import React from 'react';
import { FaYoutube, FaInstagram, FaFacebook,FaLinkedin } from "react-icons/fa";
import logo from '../assets/image/Logo-white[1].png'
import {ReactComponent as Grp1} from "../assets/image/Group 81.svg";
import {ReactComponent as Grp2} from "../assets/image/Group 82.svg";
const Contact = () => {
    return (
        <>
            <div className='flex flex-col'>
                <div
                    className='container mx-auto mt-20  rounded-xl shadow-xl py-16 px-14'>
                    <div className='flex justify-center items-center'>

                        <h1 className='font-[inter] font-bold text-2xl text-center text-flight-500  border-b-2 tracking-wide border-flight-500  '>
                            CONTACT US
                        </h1>
                    </div>
                                    <h6 className='font-[inter] font-medium text-xl align-top my-5' >
                                        Leave us a message
                                    </h6>
                    <div className='flex my-10 px-10 justify-between ' >
                            <div className='flex flex-col w-2/5 gap-20  ' >
                                    <form className=' relative flex flex-col w-full gap-5 p-5'>
                                        <input className='border border-[#B4BEC8] rounded-md p-3 w-auto ' id='name'  type='text'/>
                                        <div className='absolute w-10 h-10 top-2 bg-white left-10 right-0'>
                                            <label htmlFor='name' className=''>Name</label>
                                        </div>

                                        <input className='border border-[#B4BEC8] rounded-md p-3 w-full  ' id='email' placeholder='Email Address'  type='email'/>
                                        <textarea className='border border-[#B4BEC8] rounded-md p-3 w-full h-40  ' id='message' placeholder='Message'  />
                                        <button className='bg-flight-500 text-white p-3 rounded-md font-medium hover:bg-yellow-500 '>
                                            Send
                                        </button>
                                    </form>
                            </div>
                        <div className='flex flex-col  '>

                                    <h6 className='font-[inter] font-medium text-2xl align-top my-4' >
                                        Website: www.digimationflight.com
                                    </h6>
                                    <h6 className='font-[inter] font-medium text-2xl align-top my-4' >
                                        Email: contact@digimationflight.com
                                    </h6>

                                <div className='flex my-1  items-center gap-3 '>
                                    <FaYoutube className='w-10 h-10  ' />
                                    <FaInstagram className='w-8 h-8  ' />
                                    <FaFacebook className='w-7 h-7  ' />
                                    <FaLinkedin className='w-8 h-8  ' />
                                    <h6 className='font-[inter] font-medium text-2xl align-top mt-5' >
                                        @digimationflight
                                    </h6>
                                </div>
                            <div className='flex justify-center items-center '>

                            <img src={logo} alt='logo' className='w-56 h-56  '/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex justify-between mt-10 ' >
                    <Grp1 className='rotate-180 '/>
                    <Grp2 className='w-60 h-60  '/>
                </div>


            </div>
        </>
    );
};

export default Contact;