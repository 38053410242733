import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from "axios";

export const addCourse = createAsyncThunk('courses/addCourse', async (courseData, thunkAPI) => {
    try{
        const token = localStorage.getItem('tokenIer');
        let response = await axios.post('https://digimationserver.digimationflight.com/course/add', courseData, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    }catch (err){
        return thunkAPI.rejectWithValue(err.response.data);
    }
})

const courseSlice = createSlice({
name: 'courses',
initialState: {
    courses: [],
    loading: false,
    error: null,
},
reducers:{},
extraReducers: (builder) => {
    builder.addCase(addCourse.pending, (state) => {
        state.loading = true;
        state.error = null;
    })
        .addCase(addCourse.fulfilled, (state, action) => {
            state.loading = false;
            state.courses.push(action.payload.courses);
        })
        .addCase(addCourse.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
}
})

export default courseSlice.reducer;