import React, {Fragment, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {Menu, Transition} from '@headlessui/react'
import {LogOut, Menu as MenuIcon, Settings, User, X, ChevronDown} from 'lucide-react'
import {FaUserCircle} from 'react-icons/fa'
import {logout} from '../../redux/slice/authSlice'
import Logo from '../../assets/image/Logo.png'

export default function Navbar() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
    const userInfo = useSelector(( state ) => state.user)

    const handleLogout = () => {
        dispatch(logout())
        window.location.reload()
    }

    const navItems = [
        {name: 'Home', path: '/'},
        {name: 'About us', path: '/about'},
        {name: 'Training Programs', path: '/training'},
        {name: 'Blog', path: '/blogs'},
        {name: 'Student Ambassador', path: '/campusAmbassador'},
    ]

    return (<nav className="bg-black sticky top-0 z-50">
        <div className="  lg:container lg:mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between lg:justify-around h-20">
                <div className="flex-shrink-0">
                    <img className="h-20" src={Logo} alt="Logo"/>
                </div>
                <div className="flex  items-center">
                    <div className="hidden md:block">
                        <div className="ml-10 flex items-baseline justify-center space-x-4">
                            {navItems.map(( item ) => (<Link
                                key={item.name}
                                to={item.path}
                                className=" text-white hover:bg-gray-700/40 hover:text-white px-3 py-2 rounded-md text-lg font-medium"
                            >
                                {item.name}
                            </Link>))}

                        </div>
                    </div>
                </div>
                <div className="hidden md:block">
                    <div className=" flex items-center md:ml-6]">
                        <button
                            className="bg-black border border-flight-500 text-center text-flight-500 px-4 py-2 rounded-lg font-medium hover:bg-white hover:text-black mr-2"
                            onClick={() => navigate('/contact')}
                        >
                            Contact Us
                        </button>
                        {userInfo.isAuthenticated ? (
                            <div className="flex items-center">
                                <span className="mr-4 text-white text-lg font-semibold">
                                    Hello, {userInfo.user?.userName}
                                </span>
                                <Menu as="div" className="relative inline-block text-left">
                                    <div>
                                        <Menu.Button
                                            className="flex items-center justify-center w-10 h-10 rounded-full bg-white text-gray-950 hover:bg-gray-100 focus:outline-none">
                                            <FaUserCircle className="h-6 w-6"/>
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items
                                            className="absolute right-0 w-408 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <div className="px-1 py-1">
                                                <Menu.Item>
                                                    {( {active} ) => (<button
                                                        className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'} group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                                                    >
                                                        <User className="w-5 h-5 mr-2" aria-hidden="true"/>
                                                        Your Profile
                                                    </button>)}
                                                </Menu.Item>
                                                <Menu.Item>
                                                    {( {active} ) => (<button
                                                        className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'} group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                                                        onClick={handleLogout}
                                                    >
                                                        <LogOut className="w-5 h-5 mr-2" aria-hidden="true"/>
                                                        Sign out
                                                    </button>)}
                                                </Menu.Item>
                                            </div>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>) : (<button
                            className="bg-flight-500 text-center text-black px-4 py-2 rounded-lg font-medium hover:bg-flight-600"
                            onClick={() => navigate('/auth')}
                        >
                            Get Started
                        </button>)}
                    </div>
                </div>
                <div className="md:hidden">
                    <button
                        onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                        className="inline-flex items-center justify-center p-2 rounded-md text-white hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    >
                        <span className="sr-only">Open main menu</span>
                        {isMobileMenuOpen ? (<X className="block h-6 w-6" aria-hidden="true"/>) : (
                            <MenuIcon className="block h-6 w-6" aria-hidden="true"/>)}
                    </button>
                </div>
            </div>
        </div>

        <Transition
            show={isMobileMenuOpen}
            as={Fragment}
            enter="transition ease-out duration-100 transform"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-50 scale-100"
            leave="transition ease-in duration-75 transform"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
        >
            <div className="md:hidden bg-gray-900">
                <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                    {navItems.map(( item ) => (<Link
                        key={item.name}
                        to={item.path}
                        className="text-white hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium  text-justify "
                        onClick={() => setIsMobileMenuOpen(false)}
                    >
                        {item.name}
                    </Link>))}
                </div>
                <div className="pt-4 pb-3 border-t border-gray-700">
                    <div className="flex items-center px-5">
                        {userInfo.isAuthenticated ? (<>
                            <div className="flex-shrink-0">
                                <FaUserCircle className="h-10 w-10 text-white"/>
                            </div>
                            <div className="ml-3">
                                <div className="text-base font-medium leading-none text-white">
                                    {userInfo.user?.userName}
                                </div>
                                <div className="text-sm font-medium leading-none text-gray-400">
                                    {userInfo.user?.email}
                                </div>
                            </div>
                        </>) : (

                            <button
                                className="bg-flight-500 text-center text-black px-4 py-2 rounded-lg font-medium hover:bg-flight-600 w-full"
                                onClick={() => {
                                    navigate('/auth')
                                    setIsMobileMenuOpen(false)
                                }}
                            >
                                Get Started
                            </button>)}
                    </div>
                    {userInfo.isAuthenticated && (<div className="mt-3 px-2 space-y-1">
                        <button
                            className="block px-3 py-2 rounded-md text-base font-medium text-white hover:text-white hover:bg-gray-700 w-full text-left"
                        >
                            Your Profile
                        </button>
                        <button
                            className="block px-3 py-2 rounded-md text-base font-medium text-white hover:text-white hover:bg-gray-700 w-full text-left"
                        >
                            Settings
                        </button>
                        <button
                            className="block px-3 py-2 rounded-md text-base font-medium text-white hover:text-white hover:bg-gray-700 w-full text-left"
                            onClick={() => {
                                handleLogout()
                                setIsMobileMenuOpen(false)
                            }}
                        >
                            Sign out
                        </button>
                    </div>)}
                    <div className="mt-3 px-2">
                        <button
                            className="bg-black border border-flight-500 text-center text-flight-500 px-4 py-2 rounded-lg font-medium hover:bg-white hover:text-black w-full"
                            onClick={() => {
                                navigate('/contact')
                                setIsMobileMenuOpen(false)
                            }}
                        >
                            Contact Us
                        </button>
                    </div>
                </div>
            </div>
        </Transition>
    </nav>)
}