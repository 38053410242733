import React from 'react';
import NameSection from "../components/admin/nameSection";

const DashboardPage = () => {
    return (
        <>
            <NameSection/>
        </>
    );
};

export default DashboardPage;