import React from 'react';
import eclipse from '../assets/image/Eclipse.png'
import webSerice from '../assets/image/WebServices.png';
import Digita from '../assets/image/DigitalMarketing.png';
import Train from '../assets/image/TrainingProgram.png'
import AiML from '../assets/image/AIML.png'
import cyber from "../assets/image/CyberSecurity.png"
import "./service.css"

//motion
import {motion} from "framer-motion";


const Service = () => {
    return (<>


        <div className='bg-black  font-montserrat '>
            <div className="hidden md:block custom-shape-divider-top-1733462637 relative">
                <svg
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1200 120"
                    preserveAspectRatio="none"
                >
                    <path
                        d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                        className="shape-fill"
                    ></path>
                </svg>

                <motion.div
                    variants={{
                        offScreen: {opacity: 0, x: 200},
                        onScreen: {x: 0, opacity: 1},
                    }}
                    initial="offScreen"
                    whileInView="onScreen"
                    transition={{duration: 0.5, delay: 0.25}}
                    viewport={{once: true, amount: 0.5}}
                    className="hidden md:flex justify-center flex-col mx-auto container p-10  pt-0 relative -top-20"
                >
                    <h1 className="text-center text-4xl font-semibold text-white tracking-wide py-10">
                        Our Programs
                    </h1>
                    <div className="flex justify-center items-center gap-20">
                        <div className="hover:scale-110 transition duration-300 max-w-[350px]">
                            <div className="px-10 ">
                                <img src={webSerice} alt="Web-Development" className="mx-auto"/>
                                <h1 className="font-bold text-center text-2xl text-white items-center pb-7">
                                    Web Development
                                </h1>
                            </div>
                        </div>
                        <div className="hover:scale-110 transition duration-300 max-w-[350px]">
                            <div className="px-10 ">
                                <img src={Digita} alt="Digital Marketing" className="mx-auto"/>
                                <h1 className="font-bold text-center text-2xl text-white items-center pb-7">
                                    Digital Marketing
                                </h1>
                            </div>
                        </div>
                        <div className="hover:scale-110 transition duration-300 max-w-[350px]">
                            <div className="px-10 py-10">
                                <img src={Train} alt="Data-Science" className="mx-auto"/>
                                <h1 className="text-center font-bold text-2xl text-white items-center pb-7">
                                    Data Science
                                </h1>
                            </div>
                        </div>

                    </div>
                    <div className='flex justify-center items-center gap-20'>
                        <div className="hover:scale-110 transition duration-300 max-w-[350px]">
                            <div className="px-10 ">
                                <img src={cyber} alt="cyber-security" className="mx-auto w-96"/>
                                <h1 className="font-bold text-center text-2xl text-white items-center ">
                                    Cyber Security
                                </h1>
                            </div>
                        </div>
                        <div className="hover:scale-110 transition duration-300 max-w-[350px]">
                            <div className="px-10 ">
                                <img src={AiML} alt="Ai/Ml" className="mx-auto"/>
                                <h1 className="font-bold text-center text-2xl text-white items-center ">
                                   AI/ML
                                </h1>
                            </div>
                        </div>
                    </div>
                </motion.div>
            </div>
            {/*Mobile View*/}
            <div className='md:hidden flex flex-col justify-center items-center h-auto ' style={{
                backgroundImage: `url(${eclipse})`,
                backgroundSize: 'cover',
                backgroundPosition: 'auto',
                backgroundRepeat: 'no-repeat',


            }}>
                <h1 className='text-center text-2xl font-semibold text-white tracking-wide py-5 '>
                    Our Services
                </h1>
                <p className='font-montserrat font-semibold text-lg text-center text-white'>
                    Get the <span className='text-flight-500'> Digimation Flight</span> advantage
                </p>
                <div className=' mt-5'>
                    <div className='py-5 flex flex-col justify-center items-center'>
                        <img src={webSerice} alt='Ai' className=''/>
                        <h1 className='text-center text-white font-bold text-xl py-3 '>
                            Web Services
                        </h1>

                    </div>
                    <div className='py-5 flex flex-col justify-center items-center'>
                        <img src={Digita} alt='Digital Marketing' className=''/>
                        <h1 className='text-center text-white font-bold text-xl py-3 '>
                            Digital Marketing
                        </h1>
                    </div>
                    <div className='py-5 flex flex-col justify-center items-center'>
                        <img src={Train} alt='Training Programs' className=''/>
                        <h1 className='text-center text-white font-bold text-xl py-3 '>
                            Training programs
                        </h1>
                    </div>
                </div>

            </div>

        </div>
    </>);
};

export default Service;