import React, {useEffect} from 'react';
import AdminNavbar from "../components/Navbar/AdminNavbar";
import Footer from '../components/footer'
import {useNavigate} from "react-router-dom";

//Redux
import {useSelector} from "react-redux";

const AdminPageLayout = ({props,children}) => {
    const navigate = useNavigate();
    const auth = useSelector((state) => state.adminAuth)
    useEffect(() => {
        if(!auth.isAuthenticated){
            navigate('/digimation/users/admin')
        }
    }, [auth.isAuthenticated, navigate]);
    return (
        <>
            <AdminNavbar/>
            {children}
            <Footer />
        </>
    );
};

export default AdminPageLayout;