import React from 'react';
import campousHero from '../assets/image/campousHero.png'
import benefits from '../assets/image/Benefits.png'

const CampusAmbassdor = () => {
    return (
        <>
            <div className='flex flex-col mx-auto p-10 container '>
                <h2 className='text-4xl text-center font-montserrat font-bold'>Become a <span
                    className='text-flight-500'>Student Ambassador</span></h2>
                <div className='flex flex-col justify-center items-center my-10 pl-10'>
                    <img src={campousHero} alt='campousHero' className=' pl-10 w-3/4'/>
                    <div className='pr-10'>

                        <button
                            className='bg-flight-500 text-white font-montserrat font-bold py-3 px-4 w-44 mt-5 hover:bg-flight-600 hover:shadow-xl hover:scale-105 transition duration-300'>Apply
                            Now
                        </button>
                    </div>
                </div>

                <div className='flex flex-col justify-center items-center my-5'>
                    <h2 className='text-3xl font-montserrat font-semibold mb-5'>Benefits</h2>
                    <img src={benefits} alt='benefits' className='w-3/4 my-5'/>
                    <ul className=' px-10 text-xl list-disc space-y-2 space-x-1'>
                        <li>Receive Regular Update About exiting new <span className='text-flight-500'>product, feature, program</span> and
                            announcement
                        </li>
                        <li>Come on board our Digimation Flight and check out some awesome offers and products <span
                            className='text-flight-500'>when you level up.</span></li>
                        <li>All about that <span className='text-flight-500'>industry life</span> !</li>
                        <li>
                            Industry relevant <span className='text-flight-500'>projects </span> and relevant skills
                        </li>
                        <li>Looking for a <span className='text-flight-500'>job</span>?</li>
                    </ul>
                    <div className='pr-10 my-5'>

                        <button
                            className='bg-flight-500 text-white font-montserrat font-bold py-3 px-4 w-44 mt-5 hover:bg-flight-600 hover:shadow-xl hover:scale-105 transition duration-300'>Apply
                            Now
                        </button>
                    </div>
                </div>
                <div className='flex flex-col justify-center items-center my-10'>
                    <h1 className='text-3xl font-semibold font-montserrat'>What is Student Ambassador Program?</h1>
                    <div className='my-5 text-lg font-light font-montserrat w-[1050px]'>
                        <p className='text-justify my-5'>A Student Ambassador Program is a way for companies or
                            universities
                            to choose students to represent them and spread the word about their services. These student
                            ambassadors act as friendly faces for the brand, helping others learn more about it.</p>

                        <p className='mt-5 font-bold text-2xl'>Benefits</p>
                        <ul className='list-disc space-y-2 space-x-1 my-5'>
                            <li>Learn New Skills: Ambassadors get to improve skills like communication and leadership.
                            </li>
                            <li>Networking: They meet and connect with lots of people, which can help in their future
                                careers.
                            </li>
                            <li>Resume Boost: Being an ambassador looks good on a resume.</li>
                            <li>Extra Perks: Ambassadors may get rewards like certificates, free products, or even a
                                small salary.
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='flex flex-col items-center mx-10 '>
                    <div>
                        <h2 className='text-2xl font-montserrat font-semibold my-5'>Benefits and deliverables for Campus
                            Ambassador Program </h2>
                        <ul className=' text-lg list-decimal space-y-2 space-x-1 m-7 '>
                            <li>
                                <span className='font-semibold text-flight-500 '>Certificate of Completion</span>:
                                Elegible upon achieving 10 registrations.
                            </li>
                            <li>
                                <span className='font-semibold text-flight-500 '>Letter of Recommendation </span>:
                                Elegible upon achieving 20 registrations.
                            </li>
                            <li>
                                <span className='font-semibold text-flight-500 '>stipend</span>:

                                <ul className='list-disc px-10 '>
                                    <li>You will recieve INR 1000 per successful program registration as commision</li>
                                    <li>This stipend is entirely commission-based, allowing unlimited earning potential
                                        based on
                                        your performance.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <span className='font-semibold text-flight-500 '> Additional Rewards</span>: Achieve key
                                registration milestones to unlock rewards:
                                <ul className='list-disc px-10 '>
                                    <li>Digimation Flight Branded Pen: 50 registrations</li>
                                    <li> Branded Diary: 75 registrations</li>
                                    <li>Digimation Flight Kit (T-shirt, Diary, Bottle, and ID Card): 100 registrations
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <span className='font-semibold text-flight-500 text-pretty'> Target-Based Incentives</span>:
                                Additional perks are available upon reaching specific sales targets,
                                providing exciting opportunities for further rewards.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
};

export default CampusAmbassdor;