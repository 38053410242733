import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";

export const signIn = createAsyncThunk('auth/login', async ( userData, thunkAPI ) => {
    try {
        const response = await axios.post('https://digimationserver.digimationflight.com/auth/signIn/adminLogin', userData);
        localStorage.setItem("tokenIer", response.data.token);
        return response.data;
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data)
    }
})

const authSlice = createSlice({
    name: 'adminAuth',
    initialState: {
        user: null,
        token: localStorage.getItem('tokenIer') || null,
        loading: false,
        error: null,
        isAuthenticated: !!localStorage.getItem('tokenIer'),
    },
    reducers: {
        logout: ( state ) => {
            state.user = null;
            state.token = null;
            state.isAuthenticated = false;
            localStorage.removeItem('tokenIer');
        }
    },
    extraReducers: ( builder ) => {
        builder.addCase(signIn.pending, ( state ) => {
            state.loading = true;
            state.error = null;
        })
            .addCase(signIn.fulfilled, ( state, action ) => {
                state.loading = false;
                state.user = action.payload.user;
                state.token = action.payload.token;
                state.isAuthenticated = true;
            })
            .addCase(signIn.rejected, ( state, action ) => {
                state.loading = false;
                state.error = action.payload;
            })
    },
    devTools: true
})

export const {logout} = authSlice.actions;
export default authSlice.reducer;