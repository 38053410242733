import React from 'react';
import aboutus from '../assets/image/aboutUs.png'
import purpose from '../assets/image/Purposeful man.png'
import LightOn from '../assets/image/Light On.png'
import pcAboutus from '../assets/image/pc_back.png'

//Motion
import {motion, AnimatePresence} from "framer-motion";

const About = () => {
    return (
        <>
            <div className="hidden md:flex w-full">
                <div className="relative min-h-[600px] w-full overflow-hidden">
                    {/* Content Section */}
                    <AnimatePresence mode="wait">
                        <motion.div
                            variants={{
                                hidden: {opacity: 0, x: 200},
                                visible: {opacity: 1, x: 0}
                            }}
                            initial='hidden'
                            animate='visible'
                            transition={{duration: 0.1, delay: 0.5}}

                            className="relative z-10 mx-auto 2xl:mx-32 px-4 py-12 lg:px-8">
                            <h2 className="mb-3 flex items-center text-2xl 2xl:text-5xl font-semibold">
                                About Digimation Flight ✍️

                            </h2>
                            <div className="max-w-xl">
                                <div className="mb-8">

                                    <p className="text-muted-foreground text-xl">
                                        Detailed mission statement focusing on simplifying business processes and
                                        providing
                                        industry-relevant skills.
                                    </p>
                                </div>

                                <div>
                                    <h3 className="mb-3 text-3xl font-semibold">Our Mission</h3>
                                    <p className="text-muted-foreground text-xl">
                                        Aiming to bridge the gap between what students need and what businesses demand.
                                    </p>
                                </div>
                            </div>
                        </motion.div>
                    </AnimatePresence>


                    {/* Image Section with diagonal clip */}
                    <AnimatePresence>
                        <motion.div
                            initial={{ opacity: 0, scale: 0.8 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 0.8 }}
                            transition={{ duration: 0.8, ease: "easeInOut" }}
                            className="absolute right-0 top-0 h-full w-[60%] md:w-[55%]"
                            style={{
                                clipPath: "polygon(20% 0%, 100% 0%, 100% 100%, 0% 100%)"
                            }}
                        >
                            <img
                                src={pcAboutus}
                                alt="Business meeting"
                                className="h-full w-full object-cover"
                            />
                        </motion.div>
                    </AnimatePresence>
                </div>
            </div>

            {/*Mobile View*/}
            <div className='md:hidden w-full'>
                <div className='object-cover'>
                    <img src={aboutus} alt='aboutus' className='w-full h-full'/>
                </div>
                <div className='bg-white w-full h-full'>
                    <div className='container mx-auto px-12  flex flex-col justify-center '>
                        <div className='flex items-center gap-2 '>
                            <h2 className='text-[25px] font-bold font-montserrat '>About Digimation Flight</h2>
                            <img src={purpose} alt='purpose' className='w-7 h-7 '/>
                        </div>
                        <div className='my-5 flex justify-center items-center'>
                            <p className='text-black font-montserrat font-medium text-sm text-pretty'>Detailed mission
                                statement focusing on <br/> simplifying business processes and providing
                                industry-relevant
                                skills.</p>
                        </div>

                        <div className='my-5 flex items-center  gap-2'>
                            <h2 className='text-[25px] font-semibold font-montserrat'>Our Mission</h2>
                            <img src={LightOn} alt='Light' className='w-7 h-7'/>
                        </div>
                        <div className='mb-5 flex justify-center items-center'>
                            <p className='text-[#050505] font-montserrat  text-sm text-pretty'>Aiming to
                                bridge the gap between what students need and what businesses demand.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default About;