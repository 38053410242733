import {configureStore} from "@reduxjs/toolkit";

import authReducer from "./slice/authSlice";
import userReducer from './slice/userSlice';
import subscribeReducer from './slice/subscribe.slice'
import adminAuthReducer from './slice/admin/authSlice'
import courseReducer from './slice/admin/courseSlice'
const store = configureStore({
    reducer: {
        auth: authReducer,
        user: userReducer,
        subscribe: subscribeReducer,
        adminAuth: adminAuthReducer,
        course: courseReducer
    }
})
export default store;